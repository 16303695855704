<template>
    <div>
      <div class="d-none d-md-flex">
        <div class="header v-tierce ">
          <div class="waterMarkFB">
            <v-row class="paddingHeader">
              <v-col cols="2" lg="1">
                <v-img class="logo" src="../../public/Resource/logo-header-desktop.png" :aspect-ratio="16/9" :width="width"></v-img>
              </v-col>
              <v-col cols="8" lg="9">
                <div class="menus">
                  <router-link to="/"><h3 class="menu">Accueil</h3></router-link>
                  <router-link to="/event"><h3 class="menu">Événements</h3></router-link>
                  <router-link to="/story"><h3 class="menu">Notre histoire</h3></router-link>
                  <router-link to="/Boutique" target="_blank"><h3 class="menu ml-5">Boutique en ligne</h3></router-link>
                </div>         
              </v-col>
              <v-col cols="2" lg="2">
                <a class="FacebookSM" href="https://www.facebook.com/fleuriste.bergeron" target="_blank"></a>
                <a class="InstagramSM" href="https://www.instagram.com/bergeronfleuriste/" target="_blank" ></a>
                <a v-if="this.orderLength > 0" class="cartIcon" href="/order" style="position: relative;"> <div class="lengthCircle">{{this.orderLength}}</div></a>
                <a v-if="this.invoice" class="orderInvoice" :href="'/invoice'" style="position: relative;"></a>
              </v-col>
            </v-row>
          </div>
        </div>

      </div>

      <div class="d-flex d-md-none">
        <v-row no-gutters>
          <v-app-bar class="v-primary pa-0">
            <v-img class="logo" src="../../public/Resource/logo-header-mobile.png"></v-img>
            <v-app-bar-nav-icon variant="text" @click.stop="this.drawer = !this.drawer"></v-app-bar-nav-icon>
            <v-col cols="9" sm="10">
                <a class="FacebookSM" href="https://www.facebook.com/fleuriste.bergeron" target="_blank"></a>
                <a class="InstagramSM" href="https://www.instagram.com/bergeronfleuriste/" target="_blank" ></a>
                <a v-if="this.orderLength > 0" class="cartIcon" href="/order" style="position: relative;"> <div class="lengthCircle">{{this.orderLength}}</div></a>
                <a v-if="this.invoice" class="orderInvoice" :href="'/invoice'" style="position: relative;"></a>
            </v-col>
          </v-app-bar>
          <div class="rowPennant">
            <v-img class="birthdayPennant" src="../../public/Resource/birthdayPernnant.png"></v-img>
          </div>
          <v-navigation-drawer v-model="this.drawer" bottom temporary style="margin-top:64px !important;">
            <v-list>
              <v-list-item><router-link to="/"><h2 class="px-2">Accueil</h2></router-link></v-list-item>
              <v-list-item><router-link to="/Boutique" target="_blank"><h2 class="px-2">Boutique en ligne</h2></router-link></v-list-item>
              <v-list-item><router-link to="/event"><h2 class="px-2">Événements</h2></router-link></v-list-item>
              <v-list-item><router-link to="/story"><h2 class="px-2">Notre histoire</h2></router-link></v-list-item>
            </v-list>
          </v-navigation-drawer>
        </v-row>
      </div>
    </div>
</template>


<style scoped>
  .header{position:fixed; top: 0px; height: 150px;  width: 100% !important; box-shadow: 0px 5px 20px 0px rgb(var(--primary)) !important; z-index: 1000;}
  .waterMarkFB{ background-image: url("../../public/Resource/filigramFB.png"); background-position-x: 6%;}
  .cartIcon{height: 35px; width: 35px; position: relative; float: right; background-image: url("../../public/Resource/CartIcon.png"); background-position: center center; background-repeat: no-repeat; background-size: contain;}
  .cartIcon:hover{background-image: url("../../public/Resource/CartIconSecondary.png");}
  .orderInvoice{height: 35px; width: 35px; position: relative; float: right; background-image: url("../../public/Resource/invoice.png"); background-position: center center; background-repeat: no-repeat; background-size: contain;}
  .orderInvoice:hover{background-image: url("../../public/Resource/invoiceBlack.png");}
  .lengthCircle{background-color: rgba(var(--primary)) ; position: relative;  float: right; font-size: 11px; transform: translate(0%,160%); color: white; text-align: center; height: 14px; width: 14px; border-radius: 50%;}
  .FacebookSM{height: 35px; width: 35px; position: relative; float: right; background-image: url("../../public/Resource/Facebook.png"); background-position: center center; background-repeat: no-repeat; background-size: contain;}
  .FacebookSM:hover {background-image: url("../../public/Resource/Facebook-white.png");}
  .InstagramSM{height: 35px; width: 35px; position: relative; float: right; background-image: url("../../public/Resource/Instagram.png"); background-position: center center; background-repeat: no-repeat; background-size: contain;}
  .InstagramSM:hover {background-image: url("../../public/Resource/Instagram-white.png");}
  .rowPennant { float:right; }
  .birthdayPennant {top:10px; height: 100px; width: 114px;} 
  .logo{min-height: 120px; width: auto; }
  .paddingHeader {padding: 20px 20px 0px 20px !important;}
  .menus{position: absolute; bottom: 5px;}
  .menu{display: inline-block; text-transform: uppercase; font-weight: 200; margin-right: 10px !important;}
  .menu:hover {border-top: 2px solid rgb(var(--secondary));}
  @media handheld, only screen and (max-width: 600px) {

  }
  @media handheld, only screen and (max-width: 960px) {  
    .logo{ height: 50px !important; min-height: 0px; max-width: 60px !important; }
    .rowPennant { position: fixed; z-index: 1000;}
    .birthdayPennant {top:60px; height: 120px; width: 114px; }
    .cartIcon {background-image: url("../../public/Resource/CartIconSecondary.png");}
    .cartIcon:hover {background-image: url("../../public/Resource/CartBlackIcon.png");}
    .orderInvoice:hover{background-image: url("../../public/Resource/invoiceBlack.png");}
  }
  @media handheld, only screen and (max-width: 1264px) {}  
</style>
<script>
  import { apiECommerceExtension } from '@/mixins/apiECommerceExtension.js';
  export default {
    name: 'Header',
    mixins: [apiECommerceExtension],
    data: () => ({
      drawer: false,
      orderLength: 0,
      invoice: '',
    }),
    mounted() {
      this.orderLength = 0;
      this.orderLength = this.isOrder();
      this.invoice = this.GetInvoiceRef();
    },
    methods:{

    },
    component: {

    }
    
  }
</script>

